import {AdminActionTypes} from "./constants";
import {APICore} from "../../helpers/api/apiCore";
import {errorMessageHandler} from "../../utils";

const api = new APICore();

const INIT_STATE = {
    loading: false,
    impersonation: api.getImpersonation()?.email || null,
    stripe: {accounts: [], loading: false, error: null},
    rsvp: {rsvps: [], loading: false, error: null},
    rental: {rentals: [], loading: false, error: null},
    refundLoading: false,
};

type State = {
    impersonation: any;
    loading?: boolean;
    stripe?: any;
    rsvp?: any;
    rental?: any;
    emails?: any;
}

export type AdminActionType = {
    type: AdminActionTypes.API_RESPONSE_ERROR
        | AdminActionTypes.GET_USERS
        | AdminActionTypes.GET_RENTALS
        | AdminActionTypes.GET_RSVPS
        | AdminActionTypes.RETRY_TICKET
        | AdminActionTypes.CANCEL_TICKET
        | AdminActionTypes.RESET_CANCELLATION
        | AdminActionTypes.UPDATE_USER
        | AdminActionTypes.GET_STRIPE_ACCOUNTS
        | AdminActionTypes.DELETE_STRIPE_ACCOUNT
        | AdminActionTypes.UPDATE_RSVP
        | AdminActionTypes.UPDATE_RENTALS
        | AdminActionTypes.IMPERSONATE
        | AdminActionTypes.RESET
        | AdminActionTypes.RESET_IMPERSONATION
        | AdminActionTypes.REFUND
        | AdminActionTypes.GET_EMAILS
        | AdminActionTypes.UPDATE_EMAIL
        | AdminActionTypes.API_RESPONSE_SUCCESS;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    } | any
}

export default function Admin(state: State = INIT_STATE, action: AdminActionType) {
    switch (action.type) {
        case AdminActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AdminActionTypes.GET_USERS:
                    return {
                        ...state,
                        loading: false,
                        users: action.payload.data.users
                    }
                case AdminActionTypes.GET_STRIPE_ACCOUNTS:
                    return {
                        ...state,
                        stripe: {
                            ...state.stripe,
                            loading: false,
                            accounts: action.payload.data.accounts
                        }
                    }
                case AdminActionTypes.GET_RSVPS:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                            rsvps: action.payload.data.rsvps
                        }
                    }
                case AdminActionTypes.UPDATE_RENTALS:
                    return {
                        ...state,
                        rental: {
                            ...state.rental,
                            loading: false,
                            rentals: action.payload.data.rentals
                        }
                    }
                case AdminActionTypes.GET_RENTALS:
                    return {
                        ...state,
                        rental: {
                            ...state.rental,
                            loading: false,
                            rentals: action.payload.data.rentals
                        }
                    }
                case AdminActionTypes.RETRY_TICKET:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                        }
                    }
                case AdminActionTypes.CANCEL_TICKET:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                        },
                        ticketCancelComplete: true,
                    }
                case AdminActionTypes.UPDATE_RSVP:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                        }
                    }
                case AdminActionTypes.DELETE_STRIPE_ACCOUNT:
                    return {
                        ...state,
                        stripe: {
                            ...state.stripe,
                            loading: false,
                            accounts: action.payload.data.accounts
                        }
                    }
                case AdminActionTypes.IMPERSONATE:
                    return {
                        ...state,
                        loading: false,
                        impersonation: action.payload.data
                    }
                case AdminActionTypes.UPDATE_USER:
                    return {
                        ...state,
                        loading: false,
                    }
                case AdminActionTypes.REFUND:
                    return {
                        ...state,
                        refundLoading: false,
                        refundData: action.payload.data.returnData
                    }
                case AdminActionTypes.GET_EMAILS:
                    return {
                        ...state,
                        emails: {
                            ...state.emails,
                            loading: false,
                            emails: action.payload.data.emails
                        }
                    }
                default:
                    return {...state, loading: false}
            }
        case AdminActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AdminActionTypes.GET_USERS:
                    return {
                        ...state,
                        loading: false,
                        error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                    }
                case AdminActionTypes.GET_STRIPE_ACCOUNTS:
                    return {
                        ...state,
                        stripe: {
                            ...state.stripe,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.DELETE_STRIPE_ACCOUNT:
                    return {
                        ...state,
                        stripe: {
                            ...state.stripe,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.UPDATE_USER:
                    return {
                        ...state,
                        loading: false,
                        error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                    }
                case AdminActionTypes.GET_RSVPS:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.UPDATE_RENTALS:
                    return {
                        ...state,
                        rental: {
                            ...state.rental,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.GET_RENTALS:
                    return {
                        ...state,
                        rental: {
                            ...state.rental,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.RETRY_TICKET:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.CANCEL_TICKET:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                            error: errorMessageHandler(action.payload.error)
                        },
                        ticketCancelComplete: false,
                    }
                case AdminActionTypes.UPDATE_RSVP:
                    return {
                        ...state,
                        rsvp: {
                            ...state.rsvp,
                            loading: false,
                            error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error
                        }
                    }
                case AdminActionTypes.REFUND:
                    return {
                        ...state,
                        refundLoading: false,
                        error: errorMessageHandler(action.payload.error)
                    }
                case AdminActionTypes.GET_EMAILS:
                    return {
                        ...state,
                        emails: {
                            loading: false,
                            error: errorMessageHandler(action.payload.error)
                        }
                    }
                default:
                    return {...state, loading: false, error: typeof action.payload.error === 'object' ? Object.values(action.payload.error as {})[0] : action.payload.error}
            }
        case AdminActionTypes.IMPERSONATE:
            return {
                ...state,
                loading: true,
            }
        case AdminActionTypes.GET_USERS:
            return {
                ...state,
                loading: true,
            }
        case AdminActionTypes.UPDATE_USER:
            return {
                ...state,
                loading: true,
            }
        case AdminActionTypes.GET_STRIPE_ACCOUNTS:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.GET_RSVPS:
            return {
                ...state,
                rsvp: {
                    ...state.rsvp,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.GET_RENTALS:
            return {
                ...state,
                rental: {
                    ...state.rental,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.UPDATE_RENTALS:
            return {
                ...state,
                rental: {
                    ...state.rental,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.RETRY_TICKET:
            return {
                ...state,
                rsvp: {
                    ...state.rsvp,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.CANCEL_TICKET:
            return {
                ...state,
                rsvp: {
                    ...state.rsvp,
                    loading: true,
                    error: null
                },
                ticketCancelComplete: false,
            }
        case AdminActionTypes.UPDATE_RSVP:
            return {
                ...state,
                rsvp: {
                    ...state.rsvp,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.DELETE_STRIPE_ACCOUNT:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    loading: true,
                    error: null
                }
            }
        case AdminActionTypes.REFUND:
            return {
                ...state,
                refundLoading: true
            }
        case AdminActionTypes.RESET:
            return {
                company: {},
                loading: false,
            }
        case AdminActionTypes.RESET_IMPERSONATION:
            return {
                ...state,
                loading: false,
                impersonation: null
            }
        case AdminActionTypes.RESET_CANCELLATION:
            return {
                ...state,
                ticketCancelComplete: false
            }
        case AdminActionTypes.GET_EMAILS:
            return {
                ...state,
                emails: {
                    ...state.emails || {},
                    loading: true
                }
            }
        case AdminActionTypes.UPDATE_EMAIL:
            return {
                ...state,
                emails: {
                    ...state.emails || {},
                    loading: true
                }
            }
        default:
            return {...state};
    }
}
